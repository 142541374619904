import React from 'react';
import './App.css';
import { Ultrade } from '@ultrade/react-embed';

function App() {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Ultrade src={'https://testnet.ultrade.org'} />
        </div>
    );
}

export default App;
